<script setup lang="ts">
import { computed } from 'vue';
import {
	LOGO_EDIT_POPUP_TYPES,
	LOGO_TEXT_FONT_DEFAULT_VALUE,
	LOGO_TEXT_FONT_COLOR_DEFAULT_VALUE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { HeaderLogoType } from '@hostinger/builder-schema-validator/schema/schemaTypes';

interface Props {
	logoSrc: string;
	logoSvg: string;
	href: string | null;
	height: number;
	heightMobile: number;
	maxWidth: string;
	maxWidthMobile: string;
	siteName: string;
	selectedLogoType?: HeaderLogoType
	logoText?: string;
	logoTextFontSize?: number;
	mLogoTextFontSize?: number;
	logoTextColor?: string;
	logoTextFont?: string;
	logoTextFontWeight?: number;
}

const props = defineProps<Props>();

const logoAltTag = computed(() => (props.siteName ? `${props.siteName} logo` : ''));
const height = computed(() => `${props.height}px`);
const mHeight = computed(() => `${props.heightMobile}px`);
const width = computed(() => props.maxWidth);
const mWidth = computed(() => props.maxWidthMobile || width.value);
const logoTextFontSize = computed(() => `${props.logoTextFontSize || LOGO_TEXT_FONT_DEFAULT_VALUE}px`);
const mLogoTextFontSize = computed(() => `${props.mLogoTextFontSize || LOGO_TEXT_FONT_DEFAULT_VALUE}px`);
const selectedLogoType = computed(() => props.selectedLogoType ?? LOGO_EDIT_POPUP_TYPES.Image);
const logoTextColor = computed(() => props.logoTextColor ?? LOGO_TEXT_FONT_COLOR_DEFAULT_VALUE);
const logoTextFontWeight = computed(() => props.logoTextFontWeight || 400);

const computedStylesText = computed(() => ({
	'--logo-text-font-family': props.logoTextFont || 'var(--font-primary)',
}));
</script>

<template>
	<a
		v-if="selectedLogoType === LOGO_EDIT_POPUP_TYPES.Text"
		class="block-header-logo-text"
		:href="props.href ?? ''"
	>
		<span
			class="block-header-logo-text__text"
			:style="computedStylesText"
		>
			{{ props.logoText }}
		</span>
	</a>
	<a
		v-else
		class="block-header-logo"
		:href="props.href ?? ''"
	>
		<div
			v-if="props.logoSvg"
			class="block-header-logo__image"
			v-html="props.logoSvg"
		/>
		<img
			v-else-if="props.logoSrc"
			v-qa="'builder-siteheader-img-logo'"
			class="block-header-logo__image"
			:src="props.logoSrc"
			:alt="logoAltTag"
		>
	</a>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.block-header-logo {
	display: flex;
	width: v-bind(width);
	height: v-bind(height);
	cursor: pointer;

	&__image {
		width: 100%;
		height: 100%;
		vertical-align: middle;
		object-fit: contain;
	}
}

.block-header-logo-text {
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;

	&__text {
		font-size: v-bind(logoTextFontSize);
		color: v-bind(logoTextColor);
		font-family: var(--logo-text-font-family);
		font-weight: v-bind(logoTextFontWeight);
	}
}

@include site-engine-mobile {
	.block-header-logo {
		width: v-bind(mWidth);
		height: v-bind(mHeight);
	}

	.block-header-logo-text {
		&__text {
			font-size: v-bind(mLogoTextFontSize);
		}
	}
}
</style>
