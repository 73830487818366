import {
	SYSTEM_LOCALE,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import {
	SiteData,
	SiteBlocks,
	LINK_TYPE,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';

import {
	computed,
	ref,
} from 'vue';
import StoreApi from '@zyro-inc/site-modules/api/StoreApi';
import { EcommerceProductSeoSettingsData } from '@zyro-inc/site-modules/types';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';

const website = ref<SiteData | null>(null);
const pageData = ref<any>({});
const isSiteEngine = ref(false);
const productSeo = ref<EcommerceProductSeoSettingsData[]>([]);

export const useSiteGlobal = () => {
	const pages = computed(() => pageData.value.pages || {});
	const blocks = computed<SiteBlocks>(() => pageData.value.blocks);
	const ecommerceProductListBlocks = computed(() => Object.fromEntries(
		Object.entries(blocks.value).filter(([, { type }]) => type === BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST),
	));
	const elements = computed(() => pageData.value.elements);
	const nav = computed(() => pageData.value.nav);
	const homePageId = computed(() => pageData.value.homePageId);

	const cookieBannerAcceptText = computed(() => pageData.value.cookieBannerAcceptText);
	const cookieBannerDisclaimer = computed(() => pageData.value.cookieBannerDisclaimer);
	const cookieBannerDeclineText = computed(() => pageData.value.cookieBannerDeclineText);
	const blogReadingTimeText = computed(() => pageData.value.blogReadingTimeText);
	const meta = computed(() => pageData.value.meta);
	const metaTitle = computed(() => pageData.value.metaTitle);
	const forms = computed(() => pageData.value.forms);
	const styles = computed(() => pageData.value.styles);
	const domain = computed(() => pageData.value.domain);
	const siteId = computed<string>(() => pageData.value.siteId);
	const ecommerceStoreId = computed<string>(() => getStoreId(pageData.value.meta));
	const ecommerceShoppingCart = computed(() => pageData.value.ecommerceShoppingCart);
	const isDynamicProductPageEnabled = computed(() => pageData.value.isDynamicProductPageEnabled);
	const blogCategories = computed(() => pageData.value.blogCategories);
	const languageSwitcherLanguages = computed(() => pageData.value.languageSwitcherLanguages);
	const currentPageId = computed(() => pageData.value.currentPageId);
	const currentPageData = computed(() => pages.value[currentPageId.value]);
	const currentLocale = computed(() => pageData.value.currentLocale);
	const languageKeys = computed(() => pageData.value.languageKeys);
	const hasGoogleAdsApp = computed(() => meta.value.googleAdsIds && meta.value.googleAdsIds.length);

	const fetchProductSeo = async (storeId: string) => {
		try {
			const productData = await StoreApi.getStoreProductSeo(storeId);

			productSeo.value = productData.products;
		} catch (error) {
			console.error(error);
		}
	};

	const setWebsite = (payload: any) => {
		website.value = payload;
	};

	const setPageData = (payload: any) => {
		pageData.value = payload;
	};

	const setIsSiteEngine = (payload: boolean) => {
		isSiteEngine.value = payload;
	};

	const getPagePathFromId = ({ pageId }: any) => {
		if (!pages.value[pageId]) {
			return null;
		}

		const pageSlug = pages.value[pageId].slug;

		if ([
			SYSTEM_LOCALE,
			meta.value.defaultLocale,
		].includes(currentLocale.value)) {
			return homePageId.value === pageId ? '/' : `/${pageSlug}`;
		}

		return homePageId.value === pageId ? `/${currentLocale.value}` : `/${currentLocale.value}/${pageSlug}`;
	};

	const getButtonHref = ({
		isFormButton,
		linkedPageId,
		linkType,
		href,
	}: any) => {
		if (isFormButton) {
			return null;
		}

		// Maintenance mapper is required to clean up old website data
		// Where buttons with external links have linkType === 'internal'
		if (href) return href;

		if (linkType === LINK_TYPE.PAGE && linkedPageId) {
			const pathFromId = getPagePathFromId({
				pageId: linkedPageId,
			});

			// Fallback to href is needed for Dynamic product pages because slugs are not saved in JSON
			return pathFromId || href;
		}

		return href;
	};

	return {
		website,
		pageData,
		pages,
		blocks,
		ecommerceProductListBlocks,
		elements,
		nav,
		homePageId,
		cookieBannerAcceptText,
		cookieBannerDisclaimer,
		cookieBannerDeclineText,
		blogReadingTimeText,
		meta,
		metaTitle,
		forms,
		styles,
		domain,
		siteId,
		ecommerceStoreId,
		ecommerceShoppingCart,
		blogCategories,
		languageSwitcherLanguages,
		currentPageId,
		currentLocale,
		languageKeys,
		currentPageData,
		setWebsite,
		setPageData,
		getPagePathFromId,
		getButtonHref,
		setIsSiteEngine,
		isSiteEngine,
		fetchProductSeo,
		productSeo,
		isDynamicProductPageEnabled,
		hasGoogleAdsApp,
	};
};
